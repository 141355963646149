<template>

    <div class="picture_page">
        <div class="p_title">上传图片<span>（可上传病历或病患处）</span></div>
<!--        <lazy-component>-->
            <van-uploader v-model="fileList" :after-read="afterRead" accept="image/*" preview-size="100"
                      :max-size="20 * 1024 * 1024" @oversize="onOversize" lazy-load
                      :before-delete="beforeDelete" />
<!--        </lazy-component>-->
    </div>

</template>

<script>
    import Vue from 'vue';
    import { Toast } from 'vant'
    import axios from 'axios'
    import {wxattachqry,wxdeleteattach} from "../../api/medical";
    import {mapGetters} from "vuex";
    import { Lazyload } from 'vant';


    // 注册时可以配置额外的选项
    Vue.use(Lazyload, {
        lazyComponent: true,
        loading: "https://yz.iyzzy.com/icon/loading.gif",
        error:"https://yz.iyzzy.com/icon/load_error.png"
    });

    export default {
        computed: {
            ...mapGetters([
                'username'
            ]),
        },
        data() {
            return {
                docId: '',
                userId: '',
                uploadUrl: '',
                fileType: '1002',
                fileList: []
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            this.docId = this.$route.query.docId || ''
            this.userId = this.$route.query.userId || ''
            this.uploadUrl = process.env.VUE_APP_UPLOAD_URL
            this.getAttachList()
        },
        methods: {
            afterRead(file, detail) {
                file.status = 'uploading';
                file.message = '上传中...';

                let formData = new FormData()
                formData.append('file', file.file)
                // formData.append('docNum', this.fileType)
                // formData.append('docId', this.docId)
                const instance = axios.create({
                    withCredentials: true
                })
                let config = {
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    }
                }
                let url = this.uploadUrl + "?docId=" + this.docId + "&docNum=" + this.fileType + "&userId=" + this.userId
                instance.post(url, formData, config).then((response) => {
                    console.log(response, '---upload response')
                    if(response.data) {
                        if(response.data.error == '0') {
                            file.attachId = response.data.attchId
                            file.status = 'done';
                            Toast.success('图片上传成功');
                        } else {
                            Toast.fail('图片上传异常');
                            this.fileList.splice(detail.index, 1)
                        }
                    } else {
                        Toast.fail('图片上传异常');
                        this.fileList.splice(detail.index, 1)
                    }

                }).catch(() => {
                    Toast.fail('图片上传失败');
                    this.fileList.splice(detail.index, 1)
                })

            },
            onOversize() {
                Toast.fail('图片大小不能超过20M');
            },
            beforeDelete(file, detail) {
                let vm = this;
                this.$dialog.confirm({
                    title: null,
                    message: "是否删除该图片",
                })
                    .then(() => {
                        return new Promise((resolve, reject) => {
                            Toast.loading({
                                message: '正在删除图片...',
                                forbidClick: true,
                            })
                            let data = {
                                username: this.username,
                                attachid: file.attachId
                            }
                            wxdeleteattach(data).then(() => {
                                Toast.clear()
                                vm.fileList.splice(detail.index, 1)
                                Toast.success('图片删除成功');
                            })
                            resolve()
                        })
                    })
                    .catch(() => {
                        Toast.success('取消图片删除');
                        return false
                    });
            },
            // 获取订单信息
            getAttachList(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username,
                    docid: this.docId
                }
                wxattachqry(data).then(response => {
                    Toast.clear()
                    if(response.response_body.num > 0) {
                        for(let i = 0; i < response.response_body.num; i++) {
                            // Uploader 根据文件后缀来判断是否为图片文件
                            // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
                            let file = {
                                'attachId' : response.response_body.list[i].attachid,
                                'url' : response.response_body.list[i].url,
                                'status' : 'done',
                                'isImage': true
                            }
                            this.fileList.push(file)
                        }
                        console.log(this.fileList)
                    }
                })
            },
            arrayBufferToBase64 (buffer) {
                var binary = ''
                var bytes = new Uint8Array(buffer)
                var len = bytes.byteLength
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i])
                }
                return window.btoa(binary)
            },
        },
    }
</script>
<style lang="scss" scoped>

    /deep/.van-uploader__preview .van-image__loading {
        display: none;
    }

</style>
